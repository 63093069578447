import {ChangeDetectorRef, Component, Injector, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {DrawerService} from "./service/ui/drawer.service";
import {MatDrawer, MatSidenavModule} from "@angular/material/sidenav";
import {BreakpointObserver} from "@angular/cdk/layout";
import {AuthedUserService} from "./service/authed-user.service";
import {BoardGameBasicResponse, UserResponse} from "./model/responses";
import {Router, RouterOutlet} from "@angular/router";
import {CommonModule} from '@angular/common';
import {UserDrawerComponent} from "./shared/user-drawer.component";
import {CurrentViewDataService} from "./service/current-view-data.service";
import {DialogService} from "./service/ui/dialog.service";
import {MarkdownComponent} from "ngx-markdown";

@Component({
    selector: 'app-root',
    template: `
        <mat-sidenav-container [class]="getSidenavClass()">
            <mat-sidenav *ngIf="user" #drawer class="sidenav" opened="{{isBigScreen}}"
                         [mode]="isBigScreen ? 'side' : 'over'" fixedInViewport>
                <cb-user-drawer></cb-user-drawer>
            </mat-sidenav>
            <mat-sidenav-content class="app-container" [class.app-container-big-screen]="isBigScreen && selectedDrawerGame">
                <router-outlet></router-outlet>
            </mat-sidenav-content>
            <mat-sidenav #drawer2 class="sidenav right-sidenav" opened="{{gameDrawerOpened}}"
                         (closedStart)="currentViewDataService.selectGame(undefined, true)"
                         [mode]="isBigScreen ? 'side' : 'over'" fixedInViewport
                         position="end">
                <ng-template #gameDrawerContent></ng-template>
            </mat-sidenav>
        </mat-sidenav-container>
    `,
    styles: [`
        .sidenav-container-left {
            // GLOBAL WIDTH PATCH (SEARCH AND REMOVE THIS COMMENT WHEN DOING THINGS BETTER)
            margin-left: max(0px, calc((100% - 700px) / 2 - 314px));
            margin-right: max(0px, calc((100% - 700px) / 2 - 314px));
        }
        .sidenav-container-right {
            // GLOBAL WIDTH PATCH (SEARCH AND REMOVE THIS COMMENT WHEN DOING THINGS BETTER)
            margin-left: max(0px, calc((100% - 700px) / 2 - 420px));
            margin-right: max(0px, calc((100% - 700px) / 2 - 420px));
        }

        .right-sidenav {
            max-width: min(420px, 100vw);
            width: 420px;
            z-index: 10000;
            border-left: 1px solid rgba(255, 255, 255, 0.1) !important
        }

        .mat-drawer.mat-drawer-end {
            //margin-right: max(0px, calc((100% - 700px) / 2 - 314px));
            right: max(0px, calc((100% - 700px) / 2 - 314px));
        }

        .app-container {
            // GLOBAL WIDTH PATCH (SEARCH AND REMOVE THIS COMMENT WHEN DOING THINGS BETTER)
            margin: 0 auto;
            max-width: 700px;
        }

        .app-container-big-screen {
            margin-left: 314px;
            margin-right: 420px;
        }
    `],
    standalone: true,
    imports: [MatSidenavModule, CommonModule, RouterOutlet, UserDrawerComponent, MarkdownComponent]
})
export class AppComponent implements OnInit {

    @ViewChild('drawer') drawer?: MatDrawer;

    @ViewChild('gameDrawerContent', {read: ViewContainerRef}) gameDrawerContent?: ViewContainerRef;

    user?: UserResponse

    isBigScreen = false

    selectedDrawerGame?: BoardGameBasicResponse
    gameDrawerOpened = false

    constructor(
        private drawerService: DrawerService,
        private changeDetectorRef: ChangeDetectorRef,
        private breakpointObserver: BreakpointObserver,
        private localStorageService: AuthedUserService,
        public currentViewDataService: CurrentViewDataService,
        private router: Router,
        private dialogService: DialogService,
        private injector: Injector,
    ) {
    }

    ngOnInit(): void {
        this.user = this.localStorageService.getUserData()
        this.router.events.subscribe(() => {
            this.user = this.localStorageService.getUserData()
        })

        this.breakpointObserver.observe('(min-width: 1000px)').subscribe(result => {
            this.isBigScreen = result.matches
            if (!this.isBigScreen) {
                this.drawer?.close()
            } else {
                this.drawer?.open()
            }
            this.changeDetectorRef.detectChanges()
        })

        this.drawerService.drawerState.subscribe(state => {
            if (state || this.isBigScreen) {
                this.drawer?.open()
            } else {
                this.drawer?.close()
            }
        })

        this.currentViewDataService.selectedDrawerGameObservable.subscribe(game => {
            import('./game/game.component').then(({GameComponent}) => {
                this.gameDrawerContent!.clear()
                if (game) {
                    const component = this.gameDrawerContent!.createComponent(GameComponent, {injector: this.injector})
                    component.instance.gameSlug = game?.slug
                }

                this.selectedDrawerGame = game
                if (game) {
                    this.gameDrawerOpened = true
                    this.dialogService.onMenuOpened()
                } else {
                    this.gameDrawerOpened = false
                    this.dialogService.onMenuClosed()
                }
                console.log('selectedDrawerGame', game)
            })

        })
    }

    getSidenavClass() {
        if (!this.isBigScreen) {
            return ''
        }
        if (this.user) {
            return 'sidenav-container-left'
        } else if (this.selectedDrawerGame) {
            return 'sidenav-container-right'
        } else {
            return ''
        }
    }
}
