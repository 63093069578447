import {enableProdMode, importProvidersFrom, isDevMode} from '@angular/core';
import {environment} from './environments/environment';
import {AppComponent} from './app/app.component';
import {MatNativeDateModule} from '@angular/material/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {provideAnimations} from '@angular/platform-browser/animations';
import {BrowserModule, bootstrapApplication} from '@angular/platform-browser';
import {
    GoogleLoginProvider,
    GoogleInitOptions,
    SocialAuthServiceConfig,
    SocialLoginModule
} from '@abacritt/angularx-social-login';
import {ErrorInterceptorService} from './app/service/api/error-interceptor.service';
import {AuthInterceptorService} from './app/service/api/auth.interceptor.service';
import {HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient, withJsonpSupport} from '@angular/common/http';
import {GoogleMapsService} from './app/service/ui/google-maps.service';
import {GamePlayTemplateService} from './app/service/api/game-play-template.service';
import {CurrentViewDataService} from './app/service/current-view-data.service';
import {NavigationService} from './app/service/ui/navigation.service';
import {DrawerService} from './app/service/ui/drawer.service';
import {ScrollService} from './app/service/ui/scroll.service';
import {LocationService} from './app/service/api/location.service';
import {MeetupService} from './app/service/api/meetup.service';
import {ConversationService} from './app/service/api/conversation.service';
import {NotificationService} from './app/service/api/notification.service';
import {PostService} from './app/service/api/post.service';
import {GamePlayService} from './app/service/api/game-play.service';
import {UserFavService} from './app/service/api/user-fav.service';
import {UserBggService} from './app/service/api/user-bgg.service';
import {UserGameService} from './app/service/api/user-game.service';
import {BoardGameService} from './app/service/api/board-game.service';
import {GeoLocationService} from './app/service/geo-location.service';
import {EventService} from './app/service/api/event.service';
import {LoginService} from './app/service/login.service';
import {AuthedUserService} from './app/service/authed-user.service';
import {UserService} from './app/service/api/user.service';
import {SearchService} from "./app/service/api/search.service";
import {ShareService} from "./app/service/ui/share.service";
import {provideRouter, withViewTransitions} from "@angular/router";
import {routes} from "./routes";
import {StatsService} from "./app/service/api/stats.service";
import {DialogService} from "./app/service/ui/dialog.service";
import { provideMarkdown } from "ngx-markdown";
import {MAT_ICON_DEFAULT_OPTIONS} from "@angular/material/icon";
import {SharedDialogsService} from "./app/service/shared-dialogs.service";
import {LandingService} from "./app/service/api/landing.service";
import {ReviewService} from "./app/service/api/review.service";
import { provideServiceWorker } from '@angular/service-worker';
import {UserBlockService} from "./app/service/api/user-block.service";

const googleLoginOptions: GoogleInitOptions = {
    oneTapEnabled: false, // Don't change
    scopes: ['https://www.googleapis.com/auth/userinfo.profile']
};

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            BrowserModule, FormsModule, ReactiveFormsModule, SocialLoginModule,
            MatNativeDateModule
        ),
        UserService,
        AuthedUserService,
        LoginService,
        EventService,
        GeoLocationService,
        BoardGameService,
        UserGameService,
        UserBggService,
        UserFavService,
        GamePlayService,
        UserBlockService,
        PostService,
        NotificationService,
        ConversationService,
        SearchService,
        StatsService,
        MeetupService,
        LocationService,
        ReviewService,
        LandingService,
        ScrollService,
        ShareService,
        DrawerService,
        NavigationService,
        CurrentViewDataService,
        DialogService,
        SharedDialogsService,
        GamePlayTemplateService,
        GoogleMapsService,
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true},
        {
            provide: 'SocialAuthServiceConfig', useValue: {
                autoLogin: false, providers: [{
                    id: GoogleLoginProvider.PROVIDER_ID,
                    provider: new GoogleLoginProvider(environment.oauthClientId, googleLoginOptions)
                }], onError: (err) => {
                    console.error(err);
                }
            } as SocialAuthServiceConfig
        },
        provideAnimations(),
        provideRouter(
            routes,
            withViewTransitions({skipInitialTransition: true})
        ),
        provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
        provideMarkdown(), provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000'
          }),
    ]
})
    .catch(err => console.error(err));
