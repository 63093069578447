import {Component, OnInit} from '@angular/core';
import {
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogRef,
    MatDialogTitle
} from "@angular/material/dialog";
import {environment} from "../../environments/environment";
import {MatButtonModule} from '@angular/material/button';
import {NgIf} from "@angular/common";

@Component({
    selector: 'cb-login-dialog',
    template: `
        <h1 mat-dialog-title i18n>About Ludoya</h1>
        <mat-dialog-content>
            <div class="column">
                <div class="column align-center">
                    <span class="version" i18n>Current version: {{ environment.version }}</span>
                    <!--<img src="/assets/logo.png" class="logo" alt="Logo">-->
                </div>
                <span class="paragraph" i18n>This is an ambitious project that aims to unite board gamers from all around the globe.</span>

                <span class="subtitle" i18n>What to expect</span>
                <ul class="bullet-list">
                    <li class="bullet" i18n><b>A powerful tool:</b> The possibilities are endless. Check out our <a class="discord-link" target="_blank" href="https://www.ludoya.com/features">features</a> page to learn more.</li>
                    <li class="bullet" i18n><b>Constant updates:</b> We are always working on new features and improvements.</li>
                    <li class="bullet" i18n><b>Your opinion matters:</b> Visit our <a class="discord-link" target="_blank" href="https://www.ludoya.com/roadmap">roadmap</a> and vote for the features you want to see next.</li>
                </ul>
                <span class="subtitle" i18n>Join our community</span>
                <span class="paragraph" i18n>Connect with other board gamers, share your thoughts and stay up to date with the latest news in our <a class="discord-link" target="_blank" href="https://discord.gg/QG2yNYypVn">Discord server</a>.</span>

                <span class="paragraph" *ngIf="canRate()" i18n>And don't forget to <a class="discord-link" (click)="rateApp()">rate us</a>!</span>
            </div>
        </mat-dialog-content>
        <div mat-dialog-actions class="row space-between">
            <button mat-button cdkFocusInitial mat-dialog-close i18n>Close</button>
        </div>
    `,
    styles: [`
        .version {
            font-size: 15px;
            font-weight: 400;
            margin-bottom: 16px;
            color: #aaaaaa;
        }

        .subtitle {
            font-size: 16px;
            font-weight: 700;
            margin-top: 8px;
            margin-bottom: 8px;
            color: white;
        }

        .paragraph {
            font-size: 15px;
            font-weight: 400;
            line-height: 1.34;
            margin-bottom: 16px;
            color: #cccccc;
            max-width: 350px;
        }

        .bullet-list {
            padding-inline-start: 20px;
            margin: 0 0 16px;

            .bullet {
                font-size: 15px;
                font-weight: 400;
                line-height: 1.34;
                margin-bottom: 6px;
                color: #cccccc;
                max-width: 350px;
            }
        }

        .discord-link {
            font-weight: 700;
        }

        .logo {
            width: 100px;
            height: 100px;
            margin-bottom: 16px;
        }

        .mat-mdc-dialog-content {
            max-height: unset !important;
        }
    `],
    standalone: true,
    imports: [MatButtonModule, NgIf, MatDialogContent, MatDialogTitle, MatDialogActions, MatDialogClose]
})
export class AboutDialogComponent {

    constructor(
        private dialogRef: MatDialogRef<AboutDialogComponent>,
    ) {
    }

    canRate() {
        return (window as any).Android?.rateApp
    }

    rateApp() {
        (window as any).Android?.rateApp()
    }

    protected readonly environment = environment;
}
